import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { Flex, Box } from "rebass"
import { H1, H2, H3, H4, Ul, Li, P, Hr } from "../components/VerticalRhythm"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Figure from "../components/Figure"
import PictureSet from "../components/PictureSet"
import imgObj03 from "../imgObjs/imgObj03"

import Bio from "../components/Bio"
import Circle from "../components/Circle"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import { rhythm } from "../theme/typography"

class Blog extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Blog"
          keywords={[
            `Vera Anders`,
            `zeitlose Methoden`,
            `Stift und Papier`,
            `Schreiben`,
            `Schreibthemen`,
            ``,
          ]}
        />
        <Figure caption="">
          <PictureSet imgObj={imgObj03} />
        </Figure>
        <Section>
          <Circle px={2} py={2}>
            <FontAwesomeIcon icon={["fal", "pencil"]} size="3x" />
          </Circle>
          <H1>Blog</H1>

          <P>
            Im Buch versammele ich eher zeitlose Methoden, Tipps und Ideen. Oft
            geht es dort tatsächlich nicht nur ums Texten am PC, sondern auch
            ums analoge Schreiben. Ich zeige Ihnen, wie Sie sich das Leben mit
            Stift und Papier leichter machen, mehr hinbekommen und dabei Spaß
            haben.
          </P>
          <P>
            In den kleinen Blogartikeln hier kommen, meist ganz unabhängig vom
            Buch, Alltagsinspirationen, Gedanken und Links zu neuen Schreib- und
            Texterthemen dazu. Buch und Blog ergänzen sich so hoffentlich
            perfekt für Sie als Leserinnen und Leser.
          </P>
          <P>
            Vielleicht haben Sie eine Frage oder möchten einen Gedanken teilen?
            Dann schreiben Sie mir. Themenwünsche sind sehr willkommen an{" "}
            <a href="mailto:vera.anders@schreib-leicht.de">
              vera.anders@schreib-leicht.de
            </a>
            .
          </P>
        </Section>
        <Section>
          {posts.map(({ node }) => {
            const h1 = node.frontmatter.h1 || node.fields.slug
            return (
              <article key={node.fields.slug}>
                <Link
                  id={node.fields.slug
                    .replace(/\/blog\//g, "")
                    .replace(/\//g, "")}
                  style={{ boxShadow: `none`, color: "#1A1917" }}
                  to={node.fields.slug}
                >
                  <Flex alignItems="flex-start" flexWrap={"wrap"}>
                    <Box width={[1, 1 / 3]} pr={[0, 2]}>
                      <Img
                        fluid={
                          node.frontmatter.blog_image.childImageSharp.fluid
                        }
                        style={{
                          marginBottom: rhythm(1),
                        }}
                      />
                    </Box>
                    <Box width={[1, 2 / 3]} pl={[0, 2]}>
                      <h3
                        style={{
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        {h1}
                      </h3>

                      <small>{node.frontmatter.date}</small>
                      <section
                        style={{
                          marginTop: rhythm(1 / 2),
                        }}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              node.frontmatter.description || node.excerpt,
                          }}
                        />
                        <br />
                      </section>
                    </Box>
                  </Flex>
                </Link>
              </article>
            )
          })}
        </Section>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D.MM.YYYY")
            title
            description
            h1
            blog_image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
